<template>
  <div class="column is-one-third">

    <!-- The feedback submission complete view -->
    <div
      class="box feedback-complete has-margin-3 has-padding-3 has-text-centered"
      v-show="complete"
    >
      <figure class="has-padding-2">
        <img src="/static/images/thumbs-up.svg">
      </figure>
      <h2 class="title is-4 has-text-weight-normal has-padding-2">
        <span
          class="has-text-weight-semibold"
          v-t="`onboarding.feedback.thanks`"
        ></span>
      </h2>
    </div>

    <!-- The dafault feedback pending view -->
    <div
      class="box feedback-pending has-margin-3 has-padding-3 has-text-centered"
      v-show="!complete"
    >
      <!-- Hidden iframe to absorb the form submit redirect -->
      <iframe class="is-hidden" name="absorber" id="absorber"></iframe>
      <form :action="googleForm.action" method="POST" target="absorber">

        <!-- The form data is separated from the UI -->
        <input type="hidden" :name="`entry.${googleForm.questionIds.helpful}`" :value="wasHelpful">
        <input type="hidden" :name="`entry.${googleForm.questionIds.because}`" :value="because">

        <div class="level">
          <figure class="level-item has-padding-2 is-hidden-desktop-only">
            <img src="/static/images/double-thumbs.svg">
          </figure>
          <h2 class="level-item title is-4 has-text-weight-normal has-padding-2">
            <span
              class="has-text-weight-semibold"
              v-t="`onboarding.feedback.main`"
            ></span>
          </h2>
        </div>

        <!-- Show Y/N buttons only by default; if N, show the text area -->
        <div class="level buttons" v-show="!promptWhy">
          <button
            class="level-item button has-margin-1 active-button"
            type="submit"
            @click.once="wasHelpful = 'Yes', complete = true"
          >
            <span
              class="has-text-weight-black"
              v-t="`onboarding.feedback.actions.yes`"
            ></span>
          </button>
          <button
            class="level-item button has-margin-1 passive-button"
            type="submit"
            @click.once="wasHelpful = 'No', promptWhy = true"
          >
            <span
              class="has-text-weight-black"
              v-t="`onboarding.feedback.actions.no`"
            >
            </span>
          </button>
        </div>

        <!-- Text area to gather negative feedback -->
        <div v-show="promptWhy">
          <textarea
            rows="4"
            :placeholder="$t('onboarding.feedback.more')"
            v-model="because"
            :style="{ width: '97.5%' }"
          ></textarea>

          <button
            class="button submit-button has-text-weight-black"
            type="submit"
            @click.once="wasHelpful = '', complete = true"
            v-t="`onboarding.feedback.actions.send`"
          >
          </button>
        </div>

      </form>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      because: '',
      complete: false,
      googleForm: this.$config.googleForms.onboarding,
      promptWhy: false,
      wasHelpful: ''
    }
  }
}
</script>
