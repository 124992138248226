<template>
  <div class="column wrapper is-half-desktop is-three-fifths-touch">
    <div
      class="level box has-background-light is-marginless"
      @click="stepsComplete ? $emit('active-step', 1) : null"
      :class="{ 'active-step': step === 1 }"
    >
      <div class="level-left">
        <div class="level-item">
          <span>
            <p class="title is-3 has-text-grey-light is-overlay">1</p>
          </span>
        </div>
        <div class="level-item">
          <div>
            <p class="is-size-4 has-text-grey has-text-weight-black">
              <span v-t="`${'onboarding.steps.one.' + browser + '.main'}`"></span>
              <br>
            </p>
            <i18n
              v-if="browser === 'chrome'"
              class="is-size-6 has-text-grey has-text-weight-medium"
              path="onboarding.steps.one.chrome.body"
              tag="p"
            >
              <template #puzzle>
                <span class="has-text-weight-black" v-t="`onboarding.steps.one.chrome.puzzle`"></span>
              </template>
            </i18n>
            <i18n
              v-else
              class="is-size-6 has-text-grey has-text-weight-medium"
              path="onboarding.steps.one.generic.body"
              tag="p"
            >
              <template #store>
                <span class="has-text-weight-black" v-t="`onboarding.steps.one.generic.store`"></span>
              </template>
              <template #location>
                <span class="has-text-weight-black" v-t="`onboarding.steps.one.generic.location`"></span>
              </template>
            </i18n>
          </div>
        </div>
      </div>
    </div>
    <div class="level box has-background-light is-marginless">
      <div
        class="level-left"
        :style="buttonBoxStyle[step === 1 ? 'active' : 'inactive']['default']"
      >
        <button
          class="button is-primary has-text-white"
          @click="$emit('active-step', 2)"
          v-show="step === 1"
          v-t="`onboarding.steps.actions.next`"
        >
        </button>
      </div>
    </div>

    <div
      class="level box has-background-light is-marginless"
      @click="stepsComplete ? $emit('active-step', 2) : null"
      :class="{ 'active-step': step === 2 }"
    >
      <div class="level-left">
        <div class="level-item">
          <span>
            <p class="title is-3 has-text-grey-light is-overlay">2</p>
          </span>
        </div>
        <div class="level-item">
          <div>
            <p
              class="is-size-4 has-text-grey has-text-weight-black"
              v-t="`onboarding.steps.two.main`"
            >
              <br>
            </p>
            <p
              class="is-size-6 has-text-grey has-text-weight-medium"
              v-t="`onboarding.steps.two.body`"
            ></p>
          </div>
        </div>
      </div>
    </div>

    <div class="level box has-background-light is-marginless">
      <div
        class="level-left"
        :style="buttonBoxStyle[step === 2 ? 'active' : 'inactive']['default']"
      >
        <button
          class="button is-primary has-text-white"
          @click="$emit('active-step', 3), stepsComplete = true"
          v-show="step === 2"
          v-t="`onboarding.steps.actions.next`"
        >
        </button>
      </div>
    </div>

    <div
      class="level box has-background-light is-marginless"
      @click="stepsComplete ? $emit('active-step', 3) : null"
      :class="{ 'active-step': step === 3 }"
    >
      <div class="level-left">
        <div class="level-item">
          <span>
            <p class="title is-3 has-text-grey-light is-overlay">3</p>
          </span>
        </div>
        <div class="level-item">
          <div>
            <p
              class="is-size-4 has-text-grey has-text-weight-black"
              v-t="`onboarding.steps.three.main`"
            >
              <br>
            </p>
            <p
              class="is-size-6 has-text-grey has-text-weight-medium"
              v-t="`onboarding.steps.three.body`"
            >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="level box has-background-light">
      <div
        class="level-left"
        :style="buttonBoxStyle[step === 3 ? 'active' : 'inactive']['last']"
      >
        <button
          class="button is-primary has-text-white"
          @click.prevent="submitted = true"
          v-show="step === 3"
          v-scroll="`midpoint`"
          v-t="`onboarding.steps.actions.start`"
        >
        </button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    step: Number,
    browser: String
  },

  data () {
    return {
      stepsComplete: false,
      buttonBoxStyle: {
        active: {
          default: {
            borderLeft: '2px solid #DDDDDD',
            marginLeft: '1.7rem',
            padding: '1.5rem 4.4rem'
          },
          last: {
            borderLeft: '2px solid transparent',
            marginLeft: '1.7rem',
            padding: '1.5rem 4.4rem'
          }
        },
        inactive: {
          default: {
            borderLeft: '2px solid #DDDDDD',
            marginLeft: '1.7rem',
            padding: '0.8rem 4.4rem'
          },
          last: {
            borderLeft: '2px solid transparent',
            marginLeft: '1.7rem',
            padding: '0.8rem 4.4rem'
          }
        }
      }
    }
  }

}
</script>
