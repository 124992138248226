<template>
  <main-layout>
    <!-- Main hero section -->
    <section class="section">
      <div class="container has-text-centered">

        <h1 class="title is-1 has-text-weight-black has-margin-3">
          <span v-t="`onboarding.hero.welcome`"></span>
          <span v-t="`onboarding.hero.pouch`" class="has-text-secondary"></span>
        </h1>

        <div>
          <h5
            v-t="`onboarding.hero.save`"
            class="has-text-grey is-hidden-touch"
            :style="{ fontSize: '1.1rem', fontWeight: '600' }"
          ></h5>
        </div>

      </div>
    </section>

    <!-- Main onboarding info Steps and Screens -->
    <main class="is-hidden-mobile" :style="{ marginBottom: '3.2rem' }">
      <div class="container">

        <div class="columns is-multiline is-mobile">
          <steps
            class="is-hidden-desktop"
            :style="{ margin: '0 0 2rem 17.5%' }"
            :step="step"
            :browser="browser"
            v-on:active-step="step = $event"
          ></steps>
          <steps
            class="is-hidden-touch"
            :step="step"
            :browser="browser"
            v-on:active-step="step = $event"
          ></steps>
          <screen
            :step="step"
            :browser="browser"
          ></screen>
        </div>

      </div>
    </main>

    <!-- Introduce the Top Retailers -->
    <section id="midpoint" class="section" :style="{ padding: '1rem 0 2rem 0' }">
      <div class="container has-text-centered">

        <h3 :style="{ fontSize: '1.6rem' }">
          <i18n path="onboarding.midpoint.favourites" tag="div">
            <template #shops>
              <strong v-t="`onboarding.midpoint.shops`"></strong>
            </template>
          </i18n>
          <div
            class="is-size-5 has-text-grey has-padding-2 has-margin-1"
            v-t="`onboarding.midpoint.simple`"
          ></div>
          <i18n path="onboarding.midpoint.try" tag="div">
            <template #retailers>
              <strong v-t="`onboarding.midpoint.retailers`"></strong>
            </template>
          </i18n>
        </h3>

      </div>
    </section>

    <!-- Top Retailers section -->
    <section :style="{ marginBottom: '1rem', paddingBottom: '1.3rem' }">
      <div
        class="container"
        :style="{ paddingLeft: '0.6rem', paddingRight: '0.6rem' }"
      >

        <div class="columns is-multiline is-mobile">
          <top-retailer
            v-for="(retailer, i) in displayedRetailers"
            :key="i"
            :retailer="retailer"
          ></top-retailer>
        </div>

      </div>
    </section>

    <!-- Feedback section B -->
    <section class="is-hidden-touch" :style="{ paddingBottom: '0.4rem' }">
      <div class="container">

        <div class="columns is-centered">
          <feedback></feedback>
        </div>

      </div>
    </section>

    <!-- Last thoughts section -->
    <section :style="{ marginBottom: '4rem', marginTop: '1.3rem' }">
      <div class="container has-text-centered" v-only-locales="['en-gb']">

        <p
          class="title is-size-4 has-text-weight-black"
          v-t="`onboarding.finally.questions`"
        ></p>

        <span class="has-text-grey" :style="{ fontSize: '1.15rem', fontWeight: '600' }">
          <i18n path="onboarding.finally.check" tag="div">
            <template #blog>
              <a
                href="/blog"
                class="has-text-weight-semibold"
                v-t="`onboarding.finally.blog`"
              ></a>
            </template>
          </i18n>
        </span>

      </div>
    </section>
    <img height=”1” width=”20” :src="`https://www.emjcd.com/u?TYPE=425036&CID=1562827&AMOUNT=0&OID=${CJOID}&CURRENCY=GBP&CJEVENT=${CJEVENT}&METHOD=IMG`">
  </main-layout>
</template>

<script>
import { mapState } from 'vuex'
import uuid from 'uuid'

import Utm from '@/core/services/Utm'

import Steps from './components/Steps'
import Screen from './components/Screen'
import Feedback from './components/Feedback'
import TopRetailer from './components/TopRetailer'

export default {
  components: { Feedback, Screen, Steps, TopRetailer },

  data () {
    return {
      step: 1,
      width: 0,
      browser: this.$browser.name === 'chrome' ? 'chrome' : 'generic',
      CJOID: '',
      CJEVENT: ''
    }
  },

  computed: {
    ...mapState({
      topRetailers: state => state.onboarding.topRetailers
    }),

    // Display 6 on widescreen and tablet/mobile, 5 normal and 4 small desktop
    displayedRetailers () {
      const w = this.width
      const limit = w < 769 ? 6 : w < 1216 ? 4 : w < 1408 ? 5 : 6

      return this.topRetailers.slice(0, limit)
    }
  },

  mounted () {
    // FB Analytics event...
    this.$dispatcher.fire('app.pouchInstalled')

    window.addEventListener('resize', () => {
      this.width = window.innerWidth
    })

    this.CJEVENT = Utm.get().cjevent
    this.CJOID = uuid()
  }
}
</script>
