<template>
  <div
    class="column is-half-desktop is-three-fifths-touch"
    style="margin: auto; padding: 1.4rem 2.4rem 0 1.6rem"
  >
    <figure class="image">
      <img
        :src="`/static/images/onboarding/screen-${step}_${browser}.png`"
        :alt="
          `Pouch onboarding info screen; step ${step} of 3;`
        "
      />
    </figure>
  </div>
</template>

<script>
export default {
  props: {
    step: Number,
    browser: String
  }
}
</script>
