<template>

  <div class="column is-two-thirds-mobile" :style="{ margin: 'auto' }">
    <a
      class="box has-margin-2 has-padding-2 has-text-centered"
      :href="retailerHome(retailer.url)"
      target="_blank"
      rel="noopener noreferrer"
      @click="retailerEvent(retailer.name)"
    >
      <div class="has-margin-1">
        <figure
          class="image is-5by4"
          :style="{
            background: `url(${retailer.logo}) no-repeat center/contain`,
          }"
        >
        </figure>
      </div>

      <div class="available-codes">
        <span class="has-text-primary">
          {{ retailer.codes }}
        </span>
        <span class="has-text-grey has-text-weight-medium">
          {{ $tc( 'onboarding.retailers.codes', retailer.codes ) }}
        </span>
      </div>
    </a>
  </div>

</template>

<script>
import DomainData from '@/core/services/Domain'
import Uid from '@/core/services/Uid'

import { mixpanel } from '@/core/services/Analytics'

export default {
  props: {
    retailer: Object
  },

  methods: {
    retailerEvent (retailer) {
      const id = Uid.getOrNew()
      const param = {
        landingPage: 'onboarding',
        retailerName: retailer,
        userAction: 'topRetailerClick',
        distinct_id: id
      }
      mixpanel.identify(id)
      mixpanel.event('Top Retailers', param)
    },

    retailerHome (data) {
      const domain = new DomainData(data)

      return domain.deriveOrigin()
    }
  }
}
</script>

<style lang="sass">
a.box
  margin: auto
  padding-bottom: 0.8rem
  max-width: 260px

  &:focus
    box-shadow: none
  &:hover
    box-shadow: 0 3px 10px rgba(10, 10, 10, 0.1)
  &:active
    box-shadow: 0 3px 10px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(255,100,66,0.3)

  figure
    margin-bottom: 1rem

  div.available-codes

    span
      &:first-child
        font-weight: 800

      &:last-child
        font-weight: 300
</style>
